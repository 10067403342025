<template>
  <div style="display: flex;flex-direction: column;width: 100%;height: 100vh">
    <img src="../../../assets/share_top.png" style="width: 100%;height: 56px;object-fit: fill">
    <div style="flex:1;width: 100%;height: 100%;overflow-y: auto">
      <div style="width: 100%;padding: 20px;box-sizing: border-box;">
        <p class="song-name">{{ song_name }}</p>
        <p class="song_name_sub">{{ song_name_sub }}</p>
        <p class="quiz-question">{{ quiz_question }}</p>
        <div style="margin-top: 15px">
          <div v-for="(option,index) in options" :key="index"
               style="cursor: pointer" @click="clickOption(option,index)">
            <div style="display:flex;flex-direction: row;margin-bottom: 20px">
              <div class="option-item" :style="customOptionStyle(option)"
                   style="flex: 1;box-sizing: border-box;display: flex;flex-direction: row">
                <p style="flex: 1">{{ getOptionIndex(index) + '&nbsp&nbsp|&nbsp' + option.value }}</p>
                <div v-if="option.reviewOption">
                  <img src="../../../assets/answer_correct.png" style="width: 20px;height: 20px"
                       v-if="option.styleType == 'success'">
                  <img src="../../../assets/answer_error.png" style="width: 20px;height: 20px"
                       v-else-if="option.styleType == 'failure'">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top: 15px;display: flex;flex-direction: row;align-items: center;justify-content: center">
          <p class="last-question-btn" @click="showLastQuestion">上一题</p>
          <div style="width: 25px;"></div>
          <p class="submit-answer" @click="submitAnswer" v-if="!hasRecord">提交答案</p>
          <p class="next-question-btn" @click="showNextQuestion()" v-if="hasRecord">下一题</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getCourseInfo, getSongInfoV2, notifyCourseQuizCompleted, submitCourseSongQuiz} from "../../../api/keneng";
import {Loading} from "element-ui";

export default {
  name: "QuizAnswer",
  data() {
    return {
      course_id: this.$route.query.course_id,
      songs: [],
      currentPlaySongIndex: 0,

      options: [],
      song_name: '',
      song_name_sub: '',
      quiz_question: '',
      hasRecord: false,
      song_quiz: {}
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      getCourseInfo(this.course_id).then((res) => {
        this.songs = res.data.songs
        this.refreshSong()
      })
    },
    refreshSong() {
      let loadingInstance = Loading.service({
        fullscreen: true,
        text: "加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)",
      });
      this.resetData()
      let song = this.songs[this.currentPlaySongIndex]
      let song_id = song['_id']['\$id']
      getSongInfoV2(song_id, this.course_id).then((res) => {
        let result = res.data.data
        this.song_name = result.song_name
        this.song_name_sub = result.song_name_sub

        this.song_quiz = result.song_quiz_contents[0]

        this.quiz_question = this.song_quiz.quiz_question

        let temp_options = this.song_quiz.quiz_options

        let record = this.song_quiz.record
        let question_answer = this.song_quiz.quiz_answer //1,2,3,4,5
        let user_answer = -1
        let correct = false
        let reviewOption = false
        if (record) {
          this.hasRecord = true
          reviewOption = true
          user_answer = record.answer
          if (question_answer == user_answer) {
            correct = true
          }
        }
        for (let i = 0; i < temp_options.length; i++) {
          let obj = {
            selected: false,
            reviewOption: reviewOption,
            value: temp_options[i]
          }
          if (reviewOption) {
            let styleType = this.getOptionStyleType(i, user_answer, question_answer, correct)
            obj['styleType'] = styleType
          }
          this.options.push(obj)
        }
      }).finally(() => {
        loadingInstance.close()
      })
    },

    showLastQuestion() {
      let index = this.currentPlaySongIndex
      index--
      if (index < 0) {
        this.$message.warning('已经是第一个题目了')
        return
      }
      this.currentPlaySongIndex = index
      this.refreshSong()
    },
    showNextQuestion() {
      let index = this.currentPlaySongIndex
      index++
      if (index > (this.songs.length - 1)) {
        this.$message.warning('已经是最后一个题目了')
        return
      }
      this.currentPlaySongIndex = index
      this.refreshSong()
    },

    clickOption(option, index) {
      if (option.reviewOption) {
        return
      }
      let selected = option.selected
      for (let i = 0; i < this.options.length; i++) {
        this.options[i].selected = false
      }
      this.options[index].selected = !selected
    },

    getOptionIndex(index) {
      switch (index) {
        case 0:
          return 'A'
        case 1:
          return 'B'
        case 2:
          return 'C'
        case 3:
          return 'D'
        case 4:
          return 'E'
        case 5:
          return 'F'
      }
    },
    getOptionStyleType(index, answer, question_answer, correct) {
      //还要显示正确答案
      var styleType = 'normal'
      if (correct) {
        if (index == answer) {
          styleType = 'success'
        }
      } else {
        if (index == question_answer) {
          styleType = 'success'
        } else if (index == answer) {
          styleType = 'failure'
        }
      }
      return styleType
    },
    customOptionStyle(option) {
      let style = {}
      if (option.reviewOption) {
        let styleType = option.styleType
        if (styleType == 'normal') {
          style.background = '#FFFFFF'
          style.color = '#333333'
          style.borderColor = '#F0F0F0'
          style.borderWidth = '1px'
        } else if (styleType == 'failure') {
          style.background = '#FFF4F4'
          style.color = '#EA5350'
          style.borderColor = '#EA5350'
          style.borderWidth = '2px'
        } else if (styleType == 'success') {
          style.background = '#F3FFF2'
          style.borderColor = '#0DAF00'
          style.color = '#0DAF00'
          style.borderWidth = '2px'
        }
      } else {
        if (option.selected) {
          style.background = '#F2F5FF'
          style.borderColor = '#5D5BB5'
          style.color = '#5D5BB5'
          style.borderWidth = '2px'
        } else {
          style.background = '#FFFFFF'
          style.color = '#333333'
          style.borderColor = '#F0F0F0'
          style.borderWidth = '1px'
        }
      }
      return style
    },
    resetData() {
      this.options = []
      this.song_name = ''
      this.song_name_sub = ''
      this.quiz_question = ''
      this.hasRecord = false
      this.song_quiz = {}
    },
    submitAnswer() {
      if (this.hasRecord) {
        this.$message.error('已经回答过问题了')
        return
      }
      let selectedIndex = -1
      for (let i = 0; i < this.options.length; i++) {
        if (this.options[i].selected) {
          selectedIndex = i
          break
        }
      }
      if (selectedIndex < 0) {
        this.$message.error('请选选择答案')
        return;
      }
      selectedIndex++
      let user_answer = selectedIndex
      let song = this.songs[this.currentPlaySongIndex]
      let song_id = song['_id']['\$id']
      let params = {
        quiz_id: this.song_quiz._id.$id,
        song_id: song_id,
        course_id: this.course_id,
        answer: user_answer,
      }
      submitCourseSongQuiz(params).then((res) => {
        let result = res.data
        if (result == 0) {
          //如果是最后一个，刷新页面,然后给出提示
          if (this.checkIsLastQuestion()) {
            this.$message.success('恭喜你，完成了本次课程的所有答题～')
            this.refreshSong()
          } else {
            this.$message.success('提交成功')
            this.showNextQuestion(false)
          }
        } else {
          this.$message.success('已经回答过了～')
        }
      })
    },
    checkIsLastQuestion() {
      let index = this.currentPlaySongIndex
      index++
      if (index > (this.songs.length - 1)) {
        notifyCourseQuizCompleted(this.course_id).then((res) => {

        })
        return true
      }
      return false
    }
  }
}
</script>

<style scoped lang="less">


.submit-answer {
  cursor: pointer;
  text-align: center;
  line-height: 42px;
  border-radius: 6px;
  flex: 2;
  height: 40px;
  background: #5D5BB5;
  font-size: 14px;
  color: #FFFFFF;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
}

.last-question-btn {
  cursor: pointer;
  text-align: center;
  line-height: 42px;
  flex: 1;
  height: 42px;
  background: #FFFFFF;
  border: 2px solid #5D5BB5;
  border-radius: 6px;
  box-sizing: border-box;
  font-size: 14px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #5D5BB5;
}

.next-question-btn {
  cursor: pointer;
  text-align: center;
  line-height: 42px;
  border-radius: 6px;
  flex: 2;
  height: 40px;
  background: #5D5BB5;
  font-size: 14px;
  color: #FFFFFF;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
}

.song-name {
  color: #333333;
  font-size: 18px;
  font-weight: bold;
}

.song_name_sub {
  margin-top: 15px;
  color: #6E707D;
  font-size: 14px;
}


.option-item {
  cursor: pointer;
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  padding: 15px 20px;
  box-sizing: border-box;
  border-radius: 25px;
  border-color: #e6e7eb;
  border-width: 1px;
  border-style: solid;
}

.quiz-question {
  margin-top: 15px;
  color: #333333;
  font-size: 16px;
  line-height: 28px;
}


</style>
